#footer-content-container {
  clip-path: polygon(0 9%, 100% 0, 100% 100%, 0 100%);
  color: color("secondaryBase");
  height: auto;
  background-color: color("primary");
  padding: spacing("extra-extra-extra-large") spacing("extra-large")
    spacing("extra-large") spacing("extra-large");
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  .logo {
    font-size: font-size("medium-large");
  }
  .footer-flex-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    #social-media-icons {
      margin-bottom: pad-mar("extra-extra-large");
      #social-media-facebook {
        i {
          color: color("white");
          text-decoration: none;
          font-size: font-size("extra-large");
          -webkit-transition: 0.2s;
          -moz-transition: 0.2s;
          -o-transition: 0.2s;
          transition: 0.2s;
        }
        i:hover {
          color: color("headerlinkhover");
          -webkit-transition: 0.2s;
          -moz-transition: 0.2s;
          -o-transition: 0.2s;
          transition: 0.2s;
        }
      }
    }
  }
  .footer-flex-child-left {
    flex-basis: 40%;
    flex-grow: 2;
    #contact-anchor {
      font-size: font-size("extra-large");
      margin-bottom: pad-mar("standard");
    }
    li {
      margin-bottom: pad-mar("small");
    }
  }
  .footer-flex-child-right {
    text-align: right;
    flex-basis: 60%;
    flex-grow: 1;
  }
  #credits {
    color: color("secondaryBase");
  }
}

@media only screen and (max-width: 1000px) {
  #footer-content-container {
    * {
      word-break: break-word;
    }
    .footer-flex-child-right {
      display: none;
    }
  }
}
