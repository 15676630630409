* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  overflow: hidden;
  width: 100%;
}

body {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: color("white");
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: border-box !important;
}

.ordered-list-reset {
  list-style-type: none;
  .open-location-button {
    margin-bottom: 0;
  }
  li {
    margin-bottom: pad-mar("standard");
  }
}

img {
  display: inline-block;
  font-family: Arial;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  background-color: color("light-muted");
  border-radius: $border-radius-containers-small;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
.hidden {
  display: none;
}
