.board-members-container {
  margin-top: pad-mar("extra-extra-extra-extra-large");
  .board-member-collection {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    .outer-member-card-spacings-wrapper {
      padding: pad-mar("small");
      flex: 1;
      flex-basis: 33.333%;
      .board-member-card-spacings-wrapper {
        padding: pad-mar("medium");
        border-radius: $border-radius-containers;
        .board-member-card-container {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          .board-member-image-container {
            flex-shrink: 0;
            display: flex;
            justify-self: center;
            align-items: center;
            margin: 0 auto;
            img {
              width: 110px;
              max-height: 119px;
              height: 100%;
              object-fit: cover;
              border-radius: $border-radius-containers;
            }
          }
          .board-member-info {
            flex: 3;
            // padding: 0 pad-mar("large");
            padding-left: pad-mar("large");
            .board-member-name {
              font-family: $font-family-named-heading;
              font-size: font-size("large");
              margin-bottom: pad-mar("standard");
            }
            .board-member-contact-item {
              margin-bottom: pad-mar("small");
            }
            .board-member-contact-item:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .outer-member-card-spacings-wrapper:nth-child(6n + 1),
    .outer-member-card-spacings-wrapper:nth-child(6n + 2),
    .outer-member-card-spacings-wrapper:nth-child(6n + 3) {
      .board-member-card-spacings-wrapper {
        background-color: color("primary-muted");
      }
    }
  }
  .board-member-collection::after {
    content: "";
    flex-grow: 10000000;
    height: auto;
  }
}

@media only screen and (max-width: 1400px) {
  .board-members-container {
    .board-member-collection {
      .outer-member-card-spacings-wrapper {
        flex-basis: 50%;
        .board-member-card-spacings-wrapper {
        }
      }
      // To nullify inheritance with the nth-child before media
      .outer-member-card-spacings-wrapper:nth-child(6n + 1),
      .outer-member-card-spacings-wrapper:nth-child(6n + 2),
      .outer-member-card-spacings-wrapper:nth-child(6n + 3) {
        .board-member-card-spacings-wrapper {
          background-color: unset;
        }
      }
      .outer-member-card-spacings-wrapper:nth-child(4n + 1),
      .outer-member-card-spacings-wrapper:nth-child(4n + 2) {
        .board-member-card-spacings-wrapper {
          background-color: color("primary-muted");
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .board-members-container {
    .board-member-collection {
      .outer-member-card-spacings-wrapper {
        flex-basis: 100%;
        .board-member-card-spacings-wrapper {
        }
      }
      // To nullify inheritance with the nth-child before media
      .outer-member-card-spacings-wrapper:nth-child(4n + 1),
      .outer-member-card-spacings-wrapper:nth-child(4n + 2) {
        .board-member-card-spacings-wrapper {
          background-color: unset;
        }
      }
      .outer-member-card-spacings-wrapper:nth-child(odd) {
        .board-member-card-spacings-wrapper {
          background-color: color("primary-muted");
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .board-members-container {
    text-align: center;
    .board-member-collection {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: stretch;
      align-items: stretch;
      .outer-member-card-spacings-wrapper {
        padding: pad-mar("small");
        flex: 1;
        flex-basis: 33.333%;
        .board-member-card-spacings-wrapper {
          padding: pad-mar("small") pad-mar("standard");
          .board-member-card-container {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            .board-member-image-container {
              flex-shrink: 0;
              img {
                width: 110px;
                height: 100%;
                object-fit: cover;
                border-radius: $border-radius-containers;
              }
            }
            .board-member-info {
              flex: 3;
              padding: pad-mar("small");
              .board-member-name {
                font-family: $font-family-named-heading;
                font-size: font-size("large");
                margin-bottom: pad-mar("standard");
              }
              .board-member-contact-item {
                margin-bottom: pad-mar("small");
              }
              .board-member-contact-item:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
    .board-member-collection::after {
      content: "";
      flex-grow: 10000000;
      height: auto;
    }
  }
}
