$theme-colors: (
  "white": #fbfbfb,
  "base": #191716,
  "primary": #91b14c,
  // "secondary": #f9cb40,
  // "secondary": #dba800,
    "secondary": #F6BE00,
  "tertiary": #ffc000,
  "btnhover": rgb(253, 206, 66),
  "menu": #2d3d0e,
  "disabled": #aca9a1a2,
  "secondaryBase": #4e7400,
  "hyperlinkhover": #75a702,
  "headerlinkhover": #cae292,
  "icon": #c99700,
  "danger": #b43a1a,
  "warning": #8d131d,
  "light-muted": #ececec,
  "primary-muted": #a1be6118,
  "muted": #8e8e8e,
  "muted-dark": #6f7770,
) !default;

$btn-padding: (
  "none": initial,
  "small": 0.5rem,
  "medium-small": 0.6rem,
  "standard": 1.125rem,
  "medium": 1.5rem,
  "medium-large": 2rem,
  "large": 2.5rem,
) !default;

$font-size: (
  "extra-small": 0.6rem,
  // "small": 0.8rem,
  "small": 0.85rem,
  "standard": 1rem,
  "large": 1.1rem,
  "medium-large": 1.4rem,
  "extra-large": 1.6rem,
  "extra-extra-large": 2rem,
  "extra-extra-extra-large": 2.3rem,
) !default;

$spacing: (
  "extra-small": 0.3rem,
  "small": 0.6rem,
  "standard": 0.8rem,
  "medium": 1.2rem,
  "large": 1.6rem,
  "extra-large": 2rem,
  "extra-extra-large": 2.3rem,
  "extra-extra-extra-large": 3rem,
  "extra-extra-extra-extra-large": 4rem,
) !default;

$pad-mar: (
  "extra-small": 0.3rem,
  "small": 0.6rem,
  "standard": 0.8rem,
  "medium": 1.2rem,
  "large": 1.6rem,
  "extra-large": 2rem,
  "extra-extra-large": 2.3rem,
  "extra-extra-extra-large": 3rem,
  "extra-extra-extra-extra-large": 4rem,
) !default;

//  https://xd.adobe.com/view/fc77c972-906e-48cd-a0f7-8efc77f7905b-a924/#access_token=eyJhbGciOiJSUzI1NiIsIng1dSI6Imltc19uYTEta2V5LTEuY2VyIn0.eyJpZCI6IjE2NDQyNDA5NDc3NTRfNDc3MzMwMDAtOTA2Yy00MTNhLTk5NmMtZDEyMDEzMWI0OTczX2V3MSIsInR5cGUiOiJhY2Nlc3NfdG9rZW4iLCJjbGllbnRfaWQiOiJDb21ldFdlYjEiLCJ1c2VyX2lkIjoiRTE1RTU2RTE1RjdCMTc3ODBBNDk1RkU3QEFkb2JlSUQiLCJhcyI6Imltcy1uYTEiLCJhYV9pZCI6IkUxNUU1NkUxNUY3QjE3NzgwQTQ5NUZFN0BBZG9iZUlEIiwiY3RwIjowLCJmZyI6IldGUzRWQ0U0VkxFNUlQVUNFTVFGUkhRQURRPT09PT09Iiwic2lkIjoiMTY0NDI0MDQ3NDQ1Nl8zODhlODcxZS00NjdlLTQxNmEtOTNlMi1iZTlkZDVkMjYyNDdfZXcxIiwibW9pIjoiMTRhNmVkMjkiLCJleHBpcmVzX2luIjoiODY0MDAwMDAiLCJzY29wZSI6IiIsImNyZWF0ZWRfYXQiOiIxNjQ0MjQwOTQ3NzU0In0.U3WH-eYRaFkALKUS2-gbx-HZsQ1smcJuJCJk8a4dZJTjFAz0OlEZ3ziC0EtREmujc8oAb8NzDOgf6ecRutVZBO_bhmlY4gDfddYdUCh9_EkUU0mo4T1vCnUccQKb--QXc2yBliMOFKNwYNqNnFbiU2G6N0XsbWFCeB0Ap6YB7_Mg2h45rHOBSKC3l8Ed6v8fUlCfWn5E8Z8QPZHmfmZWtJnBW4exIMmeILO8kP3yrL43WS51IZFlgBVAdBrLjq4v_YCblpQ1ITbceW1_PFH8uA8aV8_zh-sH4dLadoWjSCvgVtWnK3ysib9n5uMwhw0xdgs-X4G-7dSluwcCUSm6zA&token_type=bearer&expires_in=86399995

$font-weight-narrow: 250;
$font-weight-standard: 400;
$font-weight-medium-bold: 550;
$font-weight-bold: 600;
$font-weight-extra-bold: 1000;
$letter-spacing-standard: 0px;
$line-height-standard: 1.3;

$font-family-base: "Calibri" !default;
$font-family-heading: "Trebuchet MS" !default;
$font-family-named-heading: "Lucida Calligraphy" !default;

$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace !default;

$border-radius: 6px !default;

$border-radius-containers-small: 1px;
$border-radius-containers: 2px;
$box-shadow-standard: 0px 3px 6px #1e1e1e12;
$box-shadow-hard: 4px 4px 3px #0f0f0f12;
