.admin-form {
  label {
    color: color("base");
    font-size: font-size("small");
    font-family: $font-family-heading;
    font-weight: $font-weight-bold;
  }
  textarea {
    resize: vertical;
  }
  input,
  textarea {
    color: color("muted-dark");
    border: none;
    background-color: color("light-muted");
    margin-bottom: pad-mar("large");
    border-radius: $border-radius-containers;
    padding: pad-mar("small") pad-mar("standard");
    border-left: 3px solid color("primary");
  }
  input:focus,
  input:hover,
  textarea:focus,
  textarea:hover {
    outline: none;
    border-left: 3px solid color("secondary");
    border-radius: $border-radius-containers;
      -webkit-transition:  0.2s;
      -moz-transition:  0.2s;
      -o-transition:  0.2s;
      transition:  0.2s;
  }
  .radio-button-list {
    input {
      cursor: pointer;
      margin: 0;
      display: inline-block;
      width: unset;
      text-align: right;
      margin: 0 pad-mar("medium") 0 pad-mar("standard");
    }
    li {
      display: flex;
      align-items: center;
      align-content: center;
    }
    li:hover {
      color: color("secondary");
      -webkit-transition:  0.2s;
      -moz-transition:  0.2s;
      -o-transition:  0.2s;
      transition:  0.2s;
    }
  }
}

// https://onaircode.com/html-css-custom-scrollbar-examples/

// for textarea

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: $border-radius-containers;
  background: rgba(0, 0, 0, 0.05);
}
*::-webkit-scrollbar-thumb {
  border-radius: $border-radius-containers;
  background: linear-gradient(rgb(187, 202, 163), #90ad85);
  // background-color: red;
  -webkit-box-shadow: $box-shadow-standard;
}
*::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(rgb(187, 202, 163), #90ad85);
}

.form-section {
  div {
    margin-bottom: pad-mar("large");
    input,
    label,
    textarea {
      display: block;
      width: 100%;
    }
    label {
      margin-bottom: pad-mar("small");
    }
  }
  .submit-form-btn-container {
    margin-top: pad-mar("extra-extra-large");
    text-align: center;
  }
}

@media screen and (max-width: 1440px) {
  .admin-form {
    label {
    }
    textarea {
    }
    input,
    textarea {
      color: color("muted-dark");
      border: none;
      background-color: color("light-muted");
      margin-bottom: pad-mar("standard");
      border-radius: $border-radius-containers;
      padding: pad-mar("small") pad-mar("standard");
      border-left: 3px solid color("primary");
    }
    input:focus,
    input:hover,
    textarea:focus,
    textarea:hover {
      outline: none;
      border-left: 3px solid color("secondary");
      border-radius: $border-radius-containers;
      -webkit-transition:  0.2s;
      -moz-transition:  0.2s;
      -o-transition:  0.2s;
      transition:  0.2s;
    }
  }

  .form-section {
    div {
      margin-bottom: pad-mar("medium");
    }
  }
}
