.attraction-event-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  // margin-top: pad-mar("extra-extra-extra-extra-large");
  .card-container {
    flex-basis: 75%;
    .closeButton {
      display: none;
    }
    .card-header {
      margin-bottom: pad-mar("extra-extra-large");
      .location-heading {
        margin-bottom: pad-mar("small");
        font-size: font-size("extra-extra-large");
      }
    }
    // TODO Fix image text
    .attraction-description-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .attraction-info {
        flex: 1;
        flex-basis: 50%;
        .attraction-location-aside {
          margin-top: pad-mar("extra-extra-extra-large");
        }
      }
      .img-container-padding {
        flex: 1;
        flex-basis: 50%;
        padding-left: pad-mar("large");
        .img-container {
          flex: 1;
          position: relative;
          cursor: pointer;
          height: 100%;
          width: 100%;
          // Fallback so image will always fill 100% container if height is not set
          display: flex;
          overflow: hidden;
          img {
            border-radius: $border-radius-containers;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .img-container::before {
          border-radius: $border-radius-containers;
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          background: transparent
            linear-gradient(180deg, #00000000 20%, #000000 100%) 0% 0% no-repeat
            padding-box;
          opacity: 0.75;
        }
      }
    }
  }
}
.attraction-card-listing-container {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  margin-left: pad-mar("extra-extra-extra-extra-large");
  .attraction-card-listing-item {
    padding: pad-mar("standard") pad-mar("small");
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    margin-bottom: pad-mar("large");
    .attraction-listing-text {
      cursor: pointer;
      margin-left: pad-mar("standard");
      h4 {
        font-size: font-size("large");
        margin-bottom: pad-mar("small");
        color: color("base");
      }
      p {
        font-size: font-size("standard");
        color: color("muted");
        margin-bottom: 0;
      }
    }
  }
  .attraction-card-listing-item:nth-child(odd) {
    background-color: color("primary-muted");
  }
  .attraction-item-active {
    border-left: solid 5px color("secondary");
    border-radius: $border-radius-containers;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    h4 {
      color: color("secondary") !important;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .attraction-event-container {
    .card-container {
      flex-basis: 60%;

      .attraction-description-container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        .img-container-padding {
          padding-left: 0;
          padding-top: pad-mar("extra-extra-large");
          .img-container {
            img {
            }
          }
        }
      }
    }
  }
  .attraction-card-listing-container {
    flex-basis: 40%;
    .listing-button-container {
      .pagination-button-container {
        // margin: pad-mar("extra-extra-extra-extra-large");
        margin: pad-mar("extra-large") pad-mar("extra-extra-large") 0
          pad-mar("extra-large");
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .card-header-spacer {
    display: none;
  }
  .attraction-event-container {
    margin: 0 pad-mar("small");
    display: block;
    .card-container {
      position: fixed;
      top: 0;
      left: 0;
      transition: 0.2s;
      height: 100%;
      width: 0;
      opacity: 0;
      overflow: hidden;
      background-color: color("white");
      // display: none;
      z-index: 9999999999999;
    }
    .fix-scroll-container {
      padding: pad-mar("extra-large") pad-mar("large");
      overflow-y: scroll;
      height: 100%;
      .attraction-description-container {
        display: block;
        .img-container {
          padding-right: 0;
        }
      }
    }
    .card-container.attraction-card-responsive-toggle {
      transition: 0.2s;
      opacity: 1;
      overflow: auto;
      width: 100%;
      .fix-scroll-container {
        position: relative;
        .closeButton {
          display: block;
          position: absolute;
          right: pad-mar("standard");
          top: pad-mar("standard");
          cursor: pointer;
          i {
            color: color("warning");
            font-size: font-size("large");
            -webkit-transition: 0.2s;
            -moz-transition: 0.2s;
            -o-transition: 0.2s;
            transition: 0.2s;
          }
          i:hover {
            color: color("danger");
            -webkit-transition: 0.2s;
            -moz-transition: 0.2s;
            -o-transition: 0.2s;
            transition: 0.2s;
          }
        }
        .card-header {
          padding-top: pad-mar("small");
        }
      }
    }
    .attraction-card-listing-container {
      margin: 0;
    }
  }
}
