.hidden-anchor-span-for-navigation {
  //  display: none;
}

.school-image-container {
  position: relative;
  flex: 1;
  display: flex;
  img {
    border-radius: $border-radius-containers;
    width: 100%;
    height: auto;
    max-height: 600px;
    object-fit: cover;
  }
}
.school-image-container::before {
  border-radius: $border-radius-containers;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  background: transparent linear-gradient(180deg, #00000000 20%, #000000 200%)
    0% 0% no-repeat padding-box;
  opacity: 0.75;
}

.school-location-aside {
  // margin-top: auto;
  margin-top: spacing("extra-extra-extra-large");
}

.gransamverkan-container {
  .gransamverkan-info-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: pad-mar("extra-large");
    .gransamverkan-info-text {
      flex: 1;
      min-width: 250px;
    }
    .gransamverkan-info-image {
      position: relative;
      flex: 1;
      display: flex;
      img {
        object-position: center;
        object-fit: cover;
        border-radius: $border-radius-containers;
        width: 100%;
        min-width: 300px;
        min-height: 250px;
      }
    }
    .gransamverkan-info-image::before {
      border-radius: $border-radius-containers;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      background: transparent
        linear-gradient(180deg, #00000000 20%, #000000 200%) 0% 0% no-repeat
        padding-box;
      opacity: 0.75;
    }
  }
  .neighbourhood-watch-list {
    .neighbourhood-name {
      font-size: font-size("large");
      margin-bottom: pad-mar("small");
      font-family: $font-family-named-heading;
    }
    li {
      margin-bottom: pad-mar("extra-small");
    }
  }
}

@media only screen and (max-width: 400px) {
  .gransamverkan-container {
    .gransamverkan-info-container {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      .gransamverkan-info-text {
        min-width: initial;
      }
      .gransamverkan-info-image {
        img {
          min-width: initial;
        }
      }
    }
    .neighbourhood-watch-list {
      .neighbourhood-name {
        font-size: font-size("large");
        margin-bottom: pad-mar("small");
        font-family: $font-family-named-heading;
      }
      li {
        margin-bottom: pad-mar("extra-small");
      }
    }
  }
}
