#banner-img {
  background-image: url("/public/assets/parallax/church.jpg");
  height: 80vh;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
}

#section-img-two {
  background-image: url("/public/assets/parallax/inn.jpg");
  height: 420px;
}

#section-img-three {
  background-image: url("/public/assets/parallax/attraction.jpg");
  height: 450px;
}

#section-img-four {
  background-image: url("/public/assets/parallax/house.jpg");
  height: 420px;
}

#section-img-five {
  background-image: url("/public/assets/parallax/golfcourse.jpg");
  height: 420px;
}

.parallax-wrapper {
  position: relative;
  .parallax-background {
    border-radius: $border-radius-containers-small;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    position: relative;
    // background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    background-attachment: fixed;
    .parallax-foreground {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      text-align: left;
      margin-left: 10rem;
    }
    #call-to-action-container {
      margin: 0 spacing("extra-extra-extra-large");
      width: 45%;
      #banner-heading {
        font-size: font-size("extra-extra-extra-large");
        color: color("white");
        margin-bottom: pad-mar("large");
        display: block;
      }
    }
  }
}
.parallax-background::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  background: transparent linear-gradient(270deg, #00000000 40%, #000000 60%) 0%
    0% no-repeat padding-box;
  opacity: 0.6;
}

.section-seperator {
  padding: 0 spacing("extra-extra-extra-large");
}

.parallax-separator-slanted-banner {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 91%);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
}

.parallax-separator-slanted {
  position: relative;
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 80%);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
}

.parallax-separator-slanted::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: -10px;
  left: 0;
  right: 0;
  bottom: 0;
  clip-path: polygon(0 73%, 100% 86%, 100% 100%, 0 94%);
  background-color: color("primary");
  background: linear-gradient(
    0deg,
    color("primary") 0%,
    color("secondaryBase") 100%
  );
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.parallax-separator-slanted-right {
  position: relative;
  clip-path: polygon(0 100%, 100% 88%, 100% 10%, 0 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
}

.parallax-separator-slanted-right::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: -10px;
  left: 0;
  right: 0;
  bottom: 0;
  clip-path: polygon(0 100%, 100% 91%, 100% 77%, 0 87%);
  background-color: color("primary");
  background: linear-gradient(
    0deg,
    color("primary") 0%,
    color("secondaryBase") 100%
  );
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media only screen and (min-width: 2300px) {
  #banner-img {
    height: 50vh;
  }
  .section-seperator {
    max-width: 2100px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1200px) {
  .parallax-wrapper {
    .parallax-background {
      background-attachment: scroll;
      #call-to-action-container {
      width: 48%;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .section-seperator {
    padding: 0 spacing("large");
  }
      #call-to-action-container {
      width: 70% !important;
      #banner-heading {
        font-size: font-size("extra-extra-large") !important;
      }
      }
}

@media only screen and (max-height: 800px) {
  #banner-img {
    min-height: 600px;
  }
  .section-seperator {
    padding: 0 spacing("large");
  }
}

@media only screen and (max-width: 500px) {
  .parallax-wrapper {
    .parallax-background {
      #call-to-action-container {
        margin: 0 pad-mar("large");
        width: unset !important;
        #banner-heading {
          margin-bottom: pad-mar("extra-large");
        }
      }
    }
  }
  .parallax-background::before {
  background: transparent linear-gradient(270deg, #00000000 10%, #000000 60%) 0%
    0% no-repeat padding-box;
  opacity: 0.7;
}
}
