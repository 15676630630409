.image-modal {
  position: relative;
  // display: none;
  position: fixed;
  z-index: 99999999999999;
  transition: 0.2s;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  background-color: rgb(251, 251, 251);
  // background-color: rgba(251, 251, 251, 0.95);
  .closeButton {
    display: block;
    position: absolute;
    right: pad-mar("standard");
    top: pad-mar("standard");
    cursor: pointer;
    i {
      color: color("warning");
      font-size: font-size("large");
      -webkit-transition: 0.2s;
      -moz-transition: 0.2s;
      -o-transition: 0.2s;
      transition: 0.2s;
    }
    i:hover {
      color: color("danger");
      -webkit-transition: 0.2s;
      -moz-transition: 0.2s;
      -o-transition: 0.2s;
      transition: 0.2s;
    }
  }
  .image-modal-content {
    margin: pad-mar("extra-extra-extra-extra-large") auto;
    width: 90%;
    flex-direction: column;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    .image-content {
      position: relative;
      flex-basis: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 90%;
      height: 100%;
      .image {
        cursor: zoom-in;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .image-info-box {
          position: absolute;
          top: 0;
          left: 0;
          background-color: color("base");
          color: color("white");
          opacity: 0.8;
          padding: pad-mar("extra-small");
          font-size: font-size("small");
          font-family: $font-family-heading;
          border-radius: $border-radius-containers;
        }
      }

      #caption-title {
        flex: 1;
        margin-top: spacing("medium");
        font-size: font-size("large");
      }
      #caption {
        flex: 1;
        margin-top: spacing("standard");
        margin-bottom: 0;
        flex-basis: 20%;
        color: color("base");
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .image-modal {
    overflow: auto;
    background-color: rgb(251, 251, 251);
    // background-color: rgba(251, 251, 251, 0.95);
    .image-modal-content {
      padding: 0 spacing("large");
      margin: pad-mar("extra-extra-extra-extra-large") auto;
      width: 100%;
      flex-direction: column;
      flex-wrap: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      .image-content {
        position: relative;
        flex-basis: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .image {
          cursor: zoom-in;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          .image-info-box {
            position: absolute;
            top: 0;
            left: 0;
            background-color: color("base");
            color: color("white");
            opacity: 0.6;
            padding: pad-mar("extra-small");
            font-size: font-size("extra-small");
            font-family: $font-family-heading;
            border-radius: $border-radius-containers;
          }
        }

        #caption-title {
          flex: 1;
          margin-top: spacing("medium");
          font-size: font-size("large");
        }
        #caption {
          flex: 1;
          margin-top: spacing("standard");
          margin-bottom: 0;
          flex-basis: 20%;
          color: color("base");
        }
      }
    }
  }
}

.image-modal.active-image-modal {
  transition: 0.2s;
  opacity: 1;
  overflow: auto;
  width: 100%;
}

.semifull-page-image {
  cursor: zoom-in;
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.full-page-image {
  // cursor: zoom-out;
  display: block;
  width: auto;
  height: auto;
}

// Unused alternative image tag function as it isn't needed.
//   const [fullSize, setFullSize] = useState(true);
//   <div>
//   <img
//     onClick={() => {
//       setFullSize(!fullSize);
//     }}
//     className={fullSize ? "full-page-image" : "semifull-page-image"}
//     loading="lazy"
//     width="800"
//     height="800"
//     src={`${url}${id}`}
//     alt={`Native sized from url ${url}${id}`}
//   />
// </div>
