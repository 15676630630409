//    Workaround to space listing at the same height as the image
.card-header-spacer {
  visibility: hidden;
  margin-bottom: spacing("large");
  .location-heading-spacer {
    margin-bottom: spacing("small");
    font-size: font-size("extra-extra-large");
  }
}

#calendar-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 0 pad-mar("extra-large") 5rem pad-mar("extra-large");
  h3 {
    padding: 0 pad-mar("standard");
  }
  .calendar-icon {
    font-size: font-size("extra-extra-extra-large");
  }
}

.calendar-event-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 0 auto;
  margin: 0 spacing("extra-extra-large");
  .card-container {
    flex-basis: 75%;
    .closeButton {
      display: none;
    }
    .card-header {
      margin-bottom: spacing("extra-extra-large");
      .location-heading {
        margin-bottom: spacing("small");
        font-size: font-size("extra-extra-large");
      }
      p {
        color: color("muted-dark");
        font-weight: $font-weight-bold;
        font-family: $font-family-heading;
      }
    }
    .calendar-description-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .img-container-padding {
        padding-right: pad-mar("large");
        flex: 1;
        flex-basis: 50%;
      .img-container {
        flex: 1;
        position: relative;
        cursor: pointer;
        height: 100%;
        width: 100%;
        display: flex;
        overflow: hidden;
        img {
          border-radius: $border-radius-containers;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .img-container::before {
        border-radius: $border-radius-containers;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        background: transparent
          linear-gradient(180deg, #00000000 20%, #000000 100%) 0% 0% no-repeat
          padding-box;
        opacity: 0.75;
      }
    }
      .calendar-info {
        flex: 1;
        flex-basis: 50%;
        .calendar-contact-aside {
          margin-top: spacing("extra-extra-large");
        }
        .calendar-location-aside {
          margin-top: spacing("extra-extra-extra-large");
        }
      }
    }
  }
  .calendar-card-listing-container {
    flex-basis: 25%;
    display: flex;
    flex-direction: column;
    margin-left: spacing("extra-extra-extra-extra-large");
    .calendar-card-listing-item {
      padding: pad-mar("standard") pad-mar("small");
      cursor: pointer;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      align-content: center;
      border-left: solid 3px color("muted-dark");
      border-radius: $border-radius-containers;
      margin-bottom: spacing("large");
      .card-listing-header {
        padding: pad-mar("small") pad-mar("extra-large");
        text-align: center;
        font-size: 3rem;
        font-weight: $font-weight-extra-bold;
      }
      .calendar-listing-text {
        margin-left: spacing("standard");
        h4 {
          font-size: font-size("large");
          margin-bottom: spacing("small");
          color: color("base");
        }
        p {
          font-size: font-size("standard");
          color: color("muted");
          // font-weight: $font-weight-bold;
          margin-bottom: 0;
        }
      }
    }
    .calendar-card-listing-item:nth-child(odd) {
      background-color: color("primary-muted");
    }
    .calendar-item-active {
      border-left: solid 5px color("secondary");
      border-radius: $border-radius-containers;
      .card-listing-header {
        color: color("secondary");
      }
    }
    .calendar-item-active:hover {
      border-left: solid 5px color("secondary");
      border-radius: $border-radius-containers;
      -webkit-transition: 0.3s;
      -moz-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      .card-listing-header {
        color: color("secondary");
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .calendar-event-container {
    .card-container {
      flex-basis: 60%;
      .calendar-description-container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        .img-container-padding {
          padding-right: 0;
          padding-bottom: spacing("large");
        .img-container {
          flex: 1;
          padding-right: 0;
        }
      }
        .calendar-info {
          flex: 1;
        }
      }
    }
    .calendar-card-listing-container {
      flex-basis: 40%;
    }
  }
}

@media only screen and (max-width: 800px) {
  .card-header-spacer {
    display: none;
  }
  #calendar-header {
    margin: 0 pad-mar("large") 5rem pad-mar("large");
    h3 {
      font-size: font-size("extra-large");
    }
    .calendar-icon {
      font-size: font-size("extra-extra-large");
    }
  }
  .calendar-event-container {
    margin: 0 spacing("small");
    display: block;
    .card-container {
      position: fixed;
      top: 0;
      left: 0;
      transition: 0.2s;
      height: 100%;
      width: 0;
      opacity: 0;
      overflow: hidden;
      background-color: color("white");
     z-index: 999999999999;
    }
    .fix-scroll-container {
      padding: spacing("extra-large") spacing("large");
      overflow-y: scroll;
      height: 100%;
      .calendar-description-container {
        display: block;
        .img-container-padding {
          padding-bottom: spacing("large");
          padding-right: 0;
        .img-container {
        }
      }
      }
    }
    .card-container.calendar-card-responsive-toggle {
      transition: 0.2s;
      opacity: 1;
      overflow: auto;
      width: 100%;
      .fix-scroll-container {
        position: relative;
        .closeButton {
          display: block;
          position: absolute;
          right: pad-mar("standard");
          top: pad-mar("standard");
          cursor: pointer;
          i {
            color: color("warning");
            font-size: font-size("large");
            -webkit-transition: 0.2s;
            -moz-transition: 0.2s;
            -o-transition: 0.2s;
            transition: 0.2s;
          }
          i:hover {
            color: color("danger");
            -webkit-transition: 0.2s;
            -moz-transition: 0.2s;
            -o-transition: 0.2s;
            transition: 0.2s;
          }
        }
        .card-header {
          padding-top: pad-mar("small");
        }
      }
    }
    .calendar-card-listing-container {
      margin: 0;
      .calendar-card-listing-item {
        display: flex;
        flex-wrap: nowrap;
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  .calendar-event-container {
    .calendar-card-listing-item {
      flex-wrap: wrap !important;
    }
  }
}
