.gallery-event-container {
  margin-top: pad-mar("extra-extra-extra-large");
  .gallery-collection {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .image-item-container {
      padding: pad-mar("small");
      flex: 1;
      flex-basis: 25%;
      .image-item {
        overflow: hidden;
        height: 250px;
        width: 100%;
        cursor: pointer;
        position: relative;
        // Fallback so image will always fill 100% container if height is not set
        display: flex;
        img {
          flex-shrink: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: $border-radius-containers;
        }
      }
      .image-item::before {
        border-radius: $border-radius-containers;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        background: transparent
          linear-gradient(180deg, #00000000 20%, #000000 100%) 0% 0% no-repeat
          padding-box;
        opacity: 0.75;
      }
    }
  }
  // Shady way to stop flex child from stretching during last line.
  .gallery-collection::after {
    content: "";
    flex-grow: 10000000;
    height: auto;
  }
}

@media only screen and (max-width: 900px) {
  .gallery-event-container {
    .gallery-collection {
      .image-item-container {
        flex-basis: 50%;
        .image-item {
          height: 200px;
          img {
          }
        }
        .image-item::before {
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .gallery-event-container {
    .gallery-collection {
      .image-item-container {
        flex-basis: 50%;
        .image-item {
          height: 100px;
          img {
          }
        }
        .image-item::before {
        }
      }
    }
  }
}
