// Shame on this... BUt it had to be done. Table is not good to display LONG info in.
.admin-item-table {
  width: 100%;
  border-collapse: separate;
  word-break: break-word;
  overflow: hidden;
  padding-right: pad-mar("large");
  td {
    overflow: hidden;
    word-break: break-word;
    text-align: left;
    padding: pad-mar("small") pad-mar("standard");
  }
  td:last-child,
  th:last-child {
    text-align: right;
  }
  thead {
    tr {
      background-color: color("primary");
    }
    th {
      white-space: nowrap;
      padding: pad-mar("standard") pad-mar("medium");
      font-size: font-size("standard");
      margin-bottom: spacing("large");
      font-family: $font-family-heading;
      color: color("secondaryBase");
      font-weight: $font-weight-bold;
           border-radius: $border-radius-containers;
       text-align: left;
    }
    th:first-child {
      border-left: 3px solid color("primary");
    }
  }
  tbody {
    tr {
      td {
        img {
          height: 100px;
          width: auto;
          object-fit: cover;
        }
      }
    }
    tr:nth-child(even) {
      background-color: color("primary-muted");
    }
    td:first-child {
      border-left: 3px solid color("white");
    }
    tr:hover {
        td:first-child {
          border-left: 3px solid color("secondary");
          border-radius: $border-radius-containers;
          -webkit-transition:  0.2s;
          -moz-transition:  0.2s;
          -o-transition:  0.2s;
          transition:  0.2s;
        }
    }
  }
  #td-emptydatabase-info {
    text-align: left;
  }
}

@media screen and (min-width: 1px) {
  .admin-item-table {
    td,
    th { 
      padding: pad-mar("standard") pad-mar("small");
      position: relative;
      padding-left: 8.2rem;
    }
    thead {
      display: none;
    }
    tbody {
      td::before {
        position: absolute;
        left:  pad-mar("large");
        font-size: font-size("standard");
        margin-left: auto;
        font-family: $font-family-heading;
        color: color("secondaryBase");
        font-weight: $font-weight-bold;
        content: attr(data-label);
      }
      td:first-child {
        border-left: 20px solid color("primary");
        border-radius: $border-radius-containers;
      }
      tr:hover {
        td {
          border-left: 20px solid color("secondary") !important;
          -webkit-transition:  0.2s;
          -moz-transition:  0.2s;
          -o-transition:  0.2s;
          transition:  0.2s;
        }
      }
      td {
        display: block;
        text-align: left;
        border-left: 20px solid color("primary");
      }
    }
    tr {
      margin-top: pad-mar("extra-large");
      display: block;
    }
  }
}