.button-reset {
  all: unset;
  cursor: pointer;
  color: color("secondaryBase");
  font-size: font-size("standard");
  font-family: $font-family-base;
}

.button {
  cursor: pointer;
  display: inline-block;
  color: color("base");
  background-color: color("secondary");
  background-image: linear-gradient(
    to bottom,
    color("secondary"),
    color("tertiary"),
    color("btnhover")
  );
  // It's 2022.. Why can't you animate gradiant?
  // https://stackoverflow.com/questions/7363141/css-transition-with-linear-gradient
  // https://codepen.io/JavaScriptJunkie/pen/pPRooV
  // background: transparent
  //   linear-gradient(180deg, color("icon") 2%, color("secondary") 95%) 0% 0%
  //   no-repeat padding-box;
  background-size: 100% 300%;
  box-shadow: 0px 3px 6px #1e1e1e12;
  border-radius: 3px;
  padding: btn-padding("medium-small") btn-padding( "large");
  // font-weight: $font-weight-standard;
  // Font-weight they want bold for old people
  font-weight: $font-weight-bold;
  text-decoration: none;
  font-size: font-size("small");
  text-align: center;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  font-family: $font-family-heading ;
}

* button:disabled,
button[disabled] {
  background: transparent
    linear-gradient(180deg, color("disabled") 100%, color("muted") 0%) 0% 0%
    no-repeat padding-box;
  color: color("muted");
  opacity: 0.7;
  i {
    color: color("disabled");
  }
}

.remove-disabled-styling {
  background: transparent !important;
}

* button:disabled::before,
button[disabled]::before {
  color: color("muted");
}

.address-btn::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f3c5";
  margin-right: spacing("small");
  vertical-align: middle;
  color: color("icon");
}

#gumlosa-tidning-btn::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f019";
  margin-right: spacing("small");
  vertical-align: middle;
  color: color("icon");
}

#listing-contacts-button::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f2b9";
  margin-right: spacing("small");
  vertical-align: middle;
  color: color("icon");
}

#gumlosa-tidning-btn {
  margin-top: pad-mar("large");
}

.listing-button::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f518";
  margin-right: spacing("small");
  vertical-align: middle; // color: color("icon") color: color("icon");
  color: color("icon");
}

.gallery-button::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f302";
  margin-right: spacing("small");
  vertical-align: middle; // color: color("icon") color: color("icon");
  color: color("icon");
}

#logout-btn {
  font-size: font-size("small") !important;
  color: color("base") !important;
  margin: 0 0 0 pad-mar("standard");
  padding: btn-padding("small") btn-padding("standard");
}

.banner-btn::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f518";
  margin-right: spacing("small");
  vertical-align: middle; // color: color("icon") color: color("icon");
  color: color("icon");
}

.btn-submit-go-back {
  font-family: $font-family-heading;
  font-size: font-size("small");
  color: color("secondaryBase");
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.btn-submit-go-back:hover {
  color: color("hyperlinkhover");
  background-color: transparent;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.icon-button {
  color: color("secondary");
  // color: color("icon");
  font-size: font-size("extra-extra-large");
  cursor: pointer;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.icon-button-trash {
  color: color("warning");
  // color: color("base");
  font-size: font-size("large");
  cursor: pointer;
  margin-right: pad-mar("medium");
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.icon-button-disabled {
  font-size: font-size("extra-extra-large");
  color: color("disabled");
  cursor: pointer;
}

a:hover {
  color: color("hyperlinkhover");
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.icon-button:hover {
  color: color("btnhover");
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.icon-button-trash:hover {
  color: color("danger");
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

button:hover,
.button:hover {
  background-position: 0 100%;
  color: color("base");
  background-color: color("btnhover");
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.icon-button-disabled:hover {
  color: color("disabled");
}

* button:disabled:hover,
button[disabled]:hover {
  background: transparent
    linear-gradient(180deg, color("disabled") 100%, color("muted") 0%) 0% 0%
    no-repeat padding-box;
  color: color("muted");
  opacity: 0.7;
  i:hover {
    color: color("disabled");
  }
}

.remove-disabled-styling:hover {
  background: transparent;
}

* button:disabled::before:hover,
button[disabled]::before:hover {
  color: color("muted");
}

@media only screen and (max-width: 1200px) {
  #membership-btn {
    margin: pad-mar("standard") pad-mar("standard");
    padding: btn-padding("small") btn-padding("standard");
  }
  #logout-btn {
    margin: pad-mar("standard");
    padding: btn-padding("small") btn-padding("standard");
  }
}
