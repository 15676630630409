* {
  line-height: $line-height-standard;
  letter-spacing: $letter-spacing-standard;
  font-size: font-size("standard");
  // word-wrap: break-word;
  font-family: $font-family-base;
  color: color("base");
}

.location-heading {
  color: color("base");
  font-family: $font-family-named-heading;
  font-weight: $font-weight-standard;
}

.location-heading-listing {
  color: color("base");
  font-family: $font-family-named-heading;
  font-weight: $font-weight-standard;
  font-size: font-size("extra-extra-large");
}

p {
  margin-bottom: spacing("medium");
  font-family: $font-family-base;
  font-size: font-size("standard");
}

a {
  // display: inline-block;
  // font-weight: $font-weight-bold;
  color: color("secondaryBase");
  font-size: font-size("standard");
  font-family: $font-family-base;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.logo {
  font-style: italic;
  color: color("white");
  margin-bottom: 0;
  font-weight: $font-weight-narrow;
  font-family: $font-family-named-heading;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: font-size("extra-extra-large");
  font-family: $font-family-heading;
  color: color("secondaryBase");
  // display: inline-block;
  word-break: break-word;
}

.image-small_description {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: pad-mar("medium");
  p {
    margin: 0;
    color: color("white");
    opacity: 1;
    font-size: font-size("small");
    font-weight: $font-weight-bold;
  }
}

li,
p {
  word-break: break-word;
}

.section-loading-message {
  font-size: font-size("large");
  margin: pad-mar("medium") pad-mar("extra-large") pad-mar("extra-extra-large")
    pad-mar("extra-large");
  font-weight: $font-weight-bold;
}

@media only screen and (max-width: 550px) {
  .gallery-event-container {
    .image-small_description {
      display: none;
    }
  }
}
