header::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

header {
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: pad-mar("standard") pad-mar("medium");
  padding-bottom: pad-mar("large");
  position: fixed;
  // position: sticky;
  width: 100%;
  height: auto;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999999;
  font-weight: $font-weight-standard;
  #header-text {
    .logo {
      cursor: pointer;
      z-index: 999999;
      font-size: font-size("extra-extra-large");
      a {
        all: unset;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
      }
      a:hover {
        color: color("headerlinkhover");
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
      }
    }
    .hamburger-icon {
      display: none;
    }
  }

  #site-navigation {
    height: 100%;
    * {
      font-family: $font-family-heading !important;
      font-size: font-size("standard");
      color: color("menu");
      #membership-btn {
        // padding: btn-padding("small") btn-padding("standard");
        color: color("base") ;
        margin: 0 0 0 pad-mar("standard");
        font-size: font-size("small");
        padding: btn-padding("small") btn-padding("medium");
      }
      
      #membership-btn:hover {
        color: color("base") !important;
      }
    }
    .site-navigation-inner-container {
      font-size: font-size("large");
   
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      align-content: center;
      .menu-link {
        font-weight: $font-weight-standard;
        display: inline-block;
        padding: pad-mar("standard") pad-mar("extra-small") pad-mar("standard")
          pad-mar("medium");
      }
      .icon-dropdown::after {
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
        content: "\f105";
        margin-left: pad-mar("small");
        vertical-align: middle;
        font-size: font-size("small");
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
      }
      .dropdown {
        position: relative;
      }
      .icon-dropdown-active:after {
        display: inline-block;
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
        content: "\f105";
        margin-left: pad-mar("small");
        vertical-align: middle;
        font-size: font-size("small");
        transform: rotate(90deg);
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
      }
      a {
        text-decoration: none;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
      }
      a:hover {
        color: color("headerlinkhover");
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
      }
      .dropdown-list {
        padding: 0;
        position: absolute;
        display: flex;
        flex-direction: column;
        transition: 0.3s;
        opacity: 0;
        height: 0;
        overflow: hidden;
        .menu-link {
          margin: 0;
          padding: pad-mar("standard") pad-mar("medium") pad-mar("standard")
            pad-mar("medium");
        }
        .menu-link:last-child {
          padding-bottom: pad-mar("large");
        }
      }
      .dropdown-list-show {
        padding: 0;
        // height: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        background-color: color("primary");

        font-family: $font-family-heading;
        clip-path: polygon(0 0, 100% 0, 100% 97%, 0 100%);
        transition: 0.3s;
        opacity: 1;
        .menu-link {
          margin: 0;
          padding: pad-mar("standard") pad-mar("medium") pad-mar("standard")
            pad-mar("medium");
        }
        .menu-link:last-child {
          padding-bottom: pad-mar("large");
        }
      }
    }
  }

  .admin-fetch-response-container {
    position: fixed;
    bottom: pad-mar("large");
    width: 40%;
    left: pad-mar("extra-large");
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    color: color("muted-dark");
    background-color: color("light-muted");
    padding: pad-mar("standard") pad-mar("standard") pad-mar("standard")
      pad-mar("large");
    box-shadow: $box-shadow-hard;
    border-radius: $border-radius-containers;
    border-left: 5px solid color("secondaryBase");
    .admin-fetch-response-text {
      // position: relative;
      margin: 0;
      font-weight: $font-weight-standard;
      padding-right: pad-mar("extra-large");
    }
    #fetch-response-btn {
      // position: absolute;
      // top: 14px;
      // right:  12px;
      font-size: font-size("large");
      cursor: pointer;
      color: color("secondaryBase");
    }
  }
}

#header-close-box-event {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
}

header::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  clip-path: polygon(0 0, 100% 0, 100% 81%, 0 100%);
  background-color: color("primary");
  // background: linear-gradient(90deg,  color("secondaryBase") 0%, color("primary")  20%);
  // background: linear-gradient(90deg, color("secondaryBase") 0%, color("primary") 70%);
}

.anchor-navigation-container {
  padding-top: 90px;
  margin-top: -90px;
}

@media only screen and (max-width: 1200px) {
  #header-close-box-event {
    background-color: color("base");
    opacity: 0.3;
  }
  header {
    .admin-fetch-response-container {
      width: 60%;
    }
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    flex-wrap: nowrap;
    #header-text {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .hamburger-icon {
        display: inline-block;
        cursor: pointer;
        color: color("menu");       
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
      }
      .hamburger-icon:hover {
        color: color("headerlinkhover");
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
      }
    }
    #site-navigation {
      * {
        #membership-btn {
          margin: pad-mar("standard");
        }
      }
      background-color: color("primary");
      width: 0;
      min-width: 0;
      top: 0;
      bottom: 0;
      left: 0;
      clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
      position: fixed;
      // display: none;
      transition: 0.3s;
      opacity: 0;
      height: 0;
      overflow: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;
      height: calc(100% - 70px);
      margin-top: 70px;
      height: auto;
      white-space: nowrap;
      .site-navigation-inner-container {
        padding: 1rem 3rem 2rem pad-mar("large");
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        align-content: flex-start;
        .dropdown-list-show {
          position: static;
          padding-left: pad-mar("extra-large");
          .menu-link {
            padding: pad-mar("standard") pad-mar("extra-small")
              pad-mar("standard") pad-mar("small");
          }
        }
      }
    }
    #site-navigation::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
      background-color: color("primary");
    }
    #site-navigation.active::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
    #site-navigation.active {
      width: 40%;
      min-width: 300px;
      display: block;
      opacity: 1;
      overflow: auto;
      z-index: 99999;
      // white-space: normal;
    }
  }
}

@media only screen and (max-width: 500px) {
  header {
    .admin-fetch-response-container {
      width: 95%;
      left: 50%;
      transform: translate(-50%);
    }
  }
  header {
    padding-bottom: 2.2rem;
    #header-text {
      .logo {
        font-size: font-size("extra-large");
        margin-right: pad-mar("standard");
      }
    }
  }
  header::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
    background-color: color("primary");
  }
}

@media only screen and (max-width: 300px) {
  header {
    #site-navigation.active {
      width: 100%;
      min-width: 100%;
      white-space: normal;
      clip-path: unset;
    }
  }
}
