.content-container {
  border-radius: $border-radius-containers;
  margin-top: pad-mar("extra-extra-large");
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  vertical-align: bottom;
  .heading {
    font-family: $font-family-heading;
    font-weight: $font-weight-bold;
    color: color("secondaryBase");
    // margin-bottom: pad-mar("extra-extra-large");
  }
  .info-heading-primary {
    font-size: font-size("extra-extra-extra-large");
    margin-bottom: pad-mar("large");
  }
  .info-heading-secondary {
    font-size: font-size("extra-large");
    margin-bottom: pad-mar("large");
  }
  .info-heading-tertiary {
    font-size: font-size("large");
    margin-bottom: pad-mar("large");
  }
  .flex-child {
    margin-bottom: pad-mar("extra-extra-extra-large");
  }
  .flex-child-right {
    padding-left: pad-mar("large");
    flex-basis: 30%;
    flex-grow: 1;
  }
  .flex-child-left {
    flex-basis: 70%;
    flex-grow: 2;
  }
  .flex-child-full {
    flex-basis: 100%;
  }
}

.admin-section-separator {
  // Put in to make up for the lost space thanks to fixed header.
  margin-top: 8rem;
  .content-container {
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .flex-child-right {
    padding-left: pad-mar("large");
    flex-basis: 30%;
    flex-grow: 1;
  }
  .flex-child-left {
    flex-basis: 70%;
    padding-left: 0;
    flex-grow: 2;
  }
}

.login-container {
  margin: pad-mar("extra-extra-extra-large") auto;
  padding: 0 pad-mar("large");
  max-width: 600px;
  width: 100%;
}

.listing-button-container {
  margin-bottom: pad-mar("extra-large");
  display: flex;
  flex-direction: column;
  flex: 1;
  .listing-button-item {
    margin-top: pad-mar("extra-extra-large");
    text-align: center;
  }
  .pagination-button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: pad-mar("extra-extra-large") pad-mar("extra-extra-large") 0
      pad-mar("extra-extra-large");
  }
}

.placeholder-content-while-loading {
  height: 100vh;
}

@media only screen and (max-width: 1400px) {
  .listing-button-container {
    .pagination-button-container {
      margin: pad-mar("extra-large") pad-mar("extra-extra-large") 0
        pad-mar("extra-large");
    }
  }
}

// Disables scroll on body for better handling
.disable-scroll-on-body-image-modal {
  overflow-y: hidden;
}

@media only screen and (max-width: 800px) {
  .disable-scroll-on-body-mobile {
    overflow-y: hidden;
  }
}

@media only screen and (max-width: 1000px) {
  .content-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    .flex-child-right {
      padding-left: 0;
    }
  }
  .admin-section-separator {
    .flex-child-right {
      padding-left: pad-mar("extra-large");
    }
  }
}

@media only screen and (max-width: 800px) {
  .admin-section-separator {
    .content-container {
      -webkit-flex-direction: column-reverse;
      flex-direction: column-reverse;
      .info-heading-primary {
        font-size: font-size("extra-large");
      }
      .info-heading-secondary {
        font-size: font-size("medium-large");
      }
      .info-heading-tertiary {
        font-size: font-size("large");
      }
      .flex-child-right {
        padding-left: 0;
      }
    }
  }
  .listing-button-container {
    .pagination-button-container {
      margin: pad-mar("extra-large") 0 0 0;
    }
  }
  .content-container {
    .info-heading-primary {
      font-size: font-size("extra-large");
    }
    .info-heading-secondary {
      font-size: font-size("medium-large") ;
    }
    .info-heading-tertiary {
      font-size: font-size("large") ;
    }
    .flex-child-right {
      padding-left: 0;
    }
  }
}

@media only screen and (max-width: 330px) {
  * {
    word-wrap: break-word !important;
  }
}
