.neighborhoodwatch-members-container {
  margin-top: pad-mar("extra-large");
  .neighborhoodwatch-member-collection {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    .neighborhoodwatch-item-outer-wrapper {
      padding: pad-mar("small");
      flex: 1;
      flex-basis: 33.333%;
      .neighborhoodwatch-item-inner-wrapper {
        padding: pad-mar("medium");
        border-radius: $border-radius-containers;
        .neighborhood-watch-list {
          li:last-child {
            margin-bottom: 0;
          }
          .neighbourhood-name {
            font-family: $font-family-named-heading;
            font-size: font-size("large");
            margin-bottom: pad-mar("standard");
          }
        }
      }
    }
    .neighborhoodwatch-item-outer-wrapper:nth-child(6n + 1),
    .neighborhoodwatch-item-outer-wrapper:nth-child(6n + 2),
    .neighborhoodwatch-item-outer-wrapper:nth-child(6n + 3) {
      .neighborhoodwatch-item-inner-wrapper {
        background-color: color("primary-muted");
      }
    }
  }
  .neighborhoodwatch-member-collection::after {
    content: "";
    flex-grow: 10000000;
    height: auto;
  }
}

@media only screen and (max-width: 1300px) {
  .neighborhoodwatch-members-container {
    .neighborhoodwatch-member-collection {
      .neighborhoodwatch-item-outer-wrapper {
        flex-basis: 50%;
        padding: pad-mar("small");
        .neighborhoodwatch-item-inner-wrapper {
          .neighborhood-watch-list {
            li:last-child {
            }
            .neighbourhood-name {
            }
          }
        }
      }
      // To nullify inheritance with the nth-child before media
      .neighborhoodwatch-item-outer-wrapper:nth-child(6n + 1),
      .neighborhoodwatch-item-outer-wrapper:nth-child(6n + 2),
      .neighborhoodwatch-item-outer-wrapper:nth-child(6n + 3) {
        .neighborhoodwatch-item-inner-wrapper {
          background-color: unset;
        }
      }
      .neighborhoodwatch-item-outer-wrapper:nth-child(4n + 1),
      .neighborhoodwatch-item-outer-wrapper:nth-child(4n + 2) {
        .neighborhoodwatch-item-inner-wrapper {
          background-color: color("primary-muted");
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .neighborhoodwatch-members-container {
    .neighborhoodwatch-member-collection {
      .neighborhoodwatch-item-outer-wrapper {
        flex-basis: 100%;
        .neighborhoodwatch-item-inner-wrapper {
          .neighborhood-watch-list {
            li:last-child {
            }
            .neighbourhood-name {
            }
          }
        }
      }
      .neighborhoodwatch-item-outer-wrapper:nth-child(4n + 1),
      .neighborhoodwatch-item-outer-wrapper:nth-child(4n + 2) {
        .neighborhoodwatch-item-inner-wrapper {
          background-color: unset;
        }
      }
      .neighborhoodwatch-item-outer-wrapper:nth-child(odd) {
        .neighborhoodwatch-item-inner-wrapper {
          background-color: color("primary-muted");
        }
      }
    }
  }
}
